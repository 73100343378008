import React, { useState } from 'react'
import { SignInRequired } from '../../components/SignInRequired'
import TransitionRouter from '../../components/TransitionRouter'
import { User } from '../../lib/documents'
import { FlexSpacer, PageDiv } from '../../theme/layout'
import { Error } from '../../theme/type'
import { CreateMemory } from './CreateMemory'
import { EditMemory } from './EditMemory'
import { Landing } from './Landing'

const PATHS = ['', 'new', 'edit']

export default function Memories () {
  const user = User.useCurrent()
  const [error, setError] = useState('')

  if (user == null) {
    return (
      <SignInRequired>
        In order to create and edit memories, you must have an account and be signed in.
      </SignInRequired>
    )
  }

  return (
    <PageDiv>
      {error && (
        <>
          <Error>{error.message}</Error>
          <FlexSpacer />
        </>
      )}
      <TransitionRouter paths={PATHS} root='memories'>
        <Landing path='/' {...{ setError }} />
        <CreateMemory path='new' {...{ setError }} />
        <EditMemory path='edit/:memoryId' {...{ setError }} />
      </TransitionRouter>
    </PageDiv>
  )
}
