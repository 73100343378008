import TextField from '@material-ui/core/TextField'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { Memory } from '../../lib/documents'
import { useFocusRef } from '../../lib/hooks'
import { ContentArea, ContentBox, FlexForm, FlexSpacer } from '../../theme/layout'
import { SmallStyledButton } from '../../theme/mui'
import { Body, SubTitle, Title } from '../../theme/type'

export function CreateMemory ({ navigate, setError }) {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const titleRef = useFocusRef()

  const createMemory = async event => {
    if (event != null) event.preventDefault()

    if (isEmpty(title) || isEmpty(content)) {
      setError({ message: 'A title and description is required to create a memory' })
      return
    }

    try {
      const memoryId = await Memory.create({ title, content })
      navigate(`../edit/${memoryId}`)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <ContentArea>
      <ContentBox>
        <Title>Create a new Memory</Title>
        <SubTitle>
          <ul>
            <li>
              You will have the opportunity to add images in the next step.
            </li>
            <li>
              The memory will not be available for others to see until you've requested review.
            </li>
          </ul>
        </SubTitle>
        <Body>
          In order to protect against digital vandalism, memories on this site are subject to
          approval. They are not edited for content, but are simply checked to make sure that they
          are not an attempt to deface this memorial site before being published for public view.
        </Body>
        <FlexForm onSubmit={createMemory} noValidate>
          <FlexSpacer />
          <TextField
            inputRef={titleRef}
            label='Memory Title'
            value={title}
            onChange={event => {
              setTitle(event.target.value)
              setError(null)
            }}
            variant='outlined'
          />
          <FlexSpacer />
          <TextField
            label='Memory Content'
            value={content}
            onChange={event => {
              setContent(event.target.value)
              setError(null)
            }}
            variant='outlined'
            multiline
          />
          <FlexSpacer />
          <SmallStyledButton type='submit'>Create Memory</SmallStyledButton>
          <FlexSpacer />
        </FlexForm>
      </ContentBox>
    </ContentArea>
  )
}
