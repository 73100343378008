import { Link, Redirect } from '@reach/router'
import { Enumify } from 'enumify'
import kebabCase from 'lodash/kebabCase'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import React from 'react'
import styled from 'styled-components/macro'
import Home from './Home'
import PasswordReset from './PasswordReset'
import Profile from './Profile'
import SignIn from './SignIn'
import SignUp from './SignUp'
import Memories from './Memories'

export const StyledLink = styled(Link)``

export default class Page extends Enumify {
  static HOME = new Page(Home, { path: '' })
  static SIGN_UP = new Page(SignUp, { signedIn: false, wildcard: true })
  static SIGN_IN = new Page(SignIn, { signedIn: false })
  static PROFILE = new Page(Profile, { signedOut: false })
  static PASSWORD_RESET = new Page(PasswordReset, { signedIn: false, signedOut: false })
  static MEMORIES = new Page(Memories, { signedOut: false, wildcard: true })
  static _ = this.closeEnum()

  constructor (
    component,
    {
      path = null,
      signedIn = true,
      signedOut = true,
      wildcard = false
    } = {}
  ) {
    super()
    this.component = component
    this._path = path
    this._wildcard = wildcard
    this.signedIn = signedIn
    this.signedOut = signedOut
  }

  get displayName () {
    return startCase(lowerCase(this.enumKey))
  }

  get path () {
    return `/${this._path == null ? kebabCase(this.enumKey) : this._path}`
  }

  get link () {
    return this.linkWith()
  }

  get route () {
    const path = this._wildcard ? `${this.path}/*` : this.path
    return <this.component key={this.enumKey} path={path} />
  }

  get redirect () {
    return <Redirect to={this.path} noThrow />
  }

  linkWith (label = this.displayName) {
    return <StyledLink key={this.enumKey} to={this.path}>{label}</StyledLink>
  }
}
