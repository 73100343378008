import TextField from '@material-ui/core/TextField'
import React from 'react'
import { EMAIL_REGEX, MINIMUM_PASSWORD_LENGTH } from '../../constants'
import { useFocusRef } from '../../lib/hooks'
import { ContentArea, ContentBox, EmailDisclaimer, FlexSpacer, InputRow } from '../../theme/layout'
import { SmallStyledButton } from '../../theme/mui'
import { SubTitle, Title } from '../../theme/type'

function InputForm ({ value, setValue, label, type, validator, onComplete }) {
  const inputRef = useFocusRef()

  const textFieldProps = {
    inputRef,
    label,
    type,
    value,
    onChange: event => setValue(event.target.value),
    variant: 'outlined'
  }
  const isValid = validator(value)
  if (!isValid) textFieldProps.error = true

  const onSubmit = event => {
    if (event != null) event.preventDefault()
    if (isValid) onComplete()
  }

  return (
    <InputRow justify='center' as='form' noValidate onSubmit={onSubmit}>
      <TextField {...textFieldProps} />
      <FlexSpacer />
      <SmallStyledButton disabled={!isValid} type='submit'>Next</SmallStyledButton>
    </InputRow>
  )
}

export const Email = ({ onComplete, email, setEmail }) => (
  <ContentArea>
    <ContentBox>
      <Title>Email Address</Title>
      <EmailDisclaimer />
      <InputForm
        label='Email Address'
        type='email'
        value={email}
        setValue={setEmail}
        validator={value => EMAIL_REGEX.test(value)}
        onComplete={onComplete}
      />
    </ContentBox>
  </ContentArea>
)

export const Password = ({ onComplete, password, setPassword }) => (
  <ContentArea>
    <ContentBox>
      <Title>Password</Title>
      <SubTitle>Passwords must be at least {MINIMUM_PASSWORD_LENGTH} characters in length.</SubTitle>
      <InputForm
        label='Password'
        type='password'
        value={password}
        setValue={setPassword}
        validator={value => value && value.length >= MINIMUM_PASSWORD_LENGTH}
        onComplete={onComplete}
      />
    </ContentBox>
  </ContentArea>
)

export const DisplayName = ({ onComplete, displayName, setDisplayName }) => (
  <ContentArea>
    <ContentBox>
      <Title>Display Name</Title>
      <SubTitle>This name will be publicly attached to any memories that you share.</SubTitle>
      <InputForm
        label='Display Name'
        type='text'
        value={displayName}
        setValue={setDisplayName}
        validator={value => value && value.length > 0}
        onComplete={onComplete}
      />
    </ContentBox>
  </ContentArea>
)
