import { Link, useLocation } from '@reach/router'
import T from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import { Memory, User } from '../lib/documents'
import { useDocument } from '../lib/hooks'
import { ContentBox } from '../theme/layout'
import { error, secondary } from '../theme/palette'
import { Body, Error, Title } from '../theme/type'

const MemoryCardBox = styled(ContentBox)`
  width: 500px;
  position: relative;
`

const Unpublished = styled(
  ({ className }) => <Body className={className}>Unpublished</Body>
)`
  color: ${error};
  font-style: italic;
`

const Edit = styled(
  ({ className, memoryId }) =>
    <Link className={className} to={`/memories/edit/${memoryId}`}>Edit Memory</Link>
)`
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 10px;
`

const CardBody = styled(Body)`text-align: left;`

const AuthorRow = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  ${Body} { font-size: 12px; }
`

const AuthorName = styled(Body)`
  text-align: left;
  font-style: italic;
  color: ${secondary};
`

function PublishedDate ({ published, publishedAt, updatedAt }) {
  if (!published) return <Unpublished />
  return (
    <Body>
      {publishedAt.toDate()}
      {updatedAt && updatedAt.seconds > publishedAt.seconds && '(edited)'}
    </Body>
  )
}

const splitParagraphs = content => content.split('\n').reduce(
  (acc, p, index) => [
    ...acc,
    (index > 0 ? <br key={`${index - 1}.5`} /> : null),
    <span key={index}>{p}</span>
  ],
  []
)

function MemoryCardLoader ({ memoryId, ...rest }) {
  const { data: memory, error } = useDocument(Memory.path(memoryId))
  if (memory == null) return null
  if (error != null) return <Error>{error.message}</Error>

  return <MemoryCardDisplay {...{ memory, memoryId, ...rest }} />
}

function MemoryCardDisplay ({ className, memory, memoryId, showUnpublished }) {
  const user = User.useCurrent()
  const { pathname } = useLocation()
  if (memory == null) {
    console.error('MemoryCardDisplay Given an null memory')
    return null
  }

  const { authorId, authorName, publishedAt, updatedAt, title, content, published } = memory
  const showEdit = !pathname.startsWith('/memories/edit') && user && user.uid === authorId

  return (published || showUnpublished) && (
    <MemoryCardBox className={className}>
      {showEdit && <Edit memoryId={memoryId} />}
      <Title>{title}</Title>
      <CardBody>{splitParagraphs(content)}</CardBody>
      <AuthorRow>
        <AuthorName>Author: {authorName}</AuthorName>
        <PublishedDate {...{ published, publishedAt, updatedAt }} />
      </AuthorRow>
    </MemoryCardBox>
  )
}

MemoryCardDisplay.propTypes = {
  className: T.string,
  memory: T.object.isRequired,
  memoryId: T.string.isRequired,
  showUnpublished: T.bool.isRequired
}

const MemoryCard = props =>
  props.memory == null ? <MemoryCardLoader {...props} /> : <MemoryCardDisplay {...props} />

MemoryCard.propTypes = {
  memoryId: T.string,
  memory: T.object,
  showUnpublished: T.bool
}

MemoryCard.defaultProps = {
  showUnpublished: false
}

export default MemoryCard
