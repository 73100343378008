import React from 'react'
import { SignInRequired } from '../components/SignInRequired'
import { User } from '../lib/documents'
import { ContentBox, PageDiv } from '../theme/layout'
import { Body, Label, Title } from '../theme/type'

function Profile () {
  const user = User.useCurrent()

  if (user == null) {
    return (
      <SignInRequired>
        In order to view and edit your profile, you must have an account and be signed in
      </SignInRequired>
    )
  }

  return user && (
    <PageDiv>
      <ContentBox>
        <Title>Profile</Title>
        <Body>
          <Label>Display Name:</Label> {user.displayName}
          <br />
          <Label>Email:</Label> {user.email}
        </Body>
      </ContentBox>
    </PageDiv>
  )
}

export default Profile
