import { lighten, rgba } from 'polished'
import { css } from 'styled-components/macro'

export const background = '#b2bcc5'
export const boxBackground = lighten(0.25, background)
export const primary = '#7c5387'
export const secondary = '#1f3986'
export const confirm = '#59977e'
export const error = '#b76d95'
export const text = primary

export const boxShadow = (distance = 20) => {
  const pos = `${Math.floor(distance / 4)}px`
  const blur = `${distance}px`
  const spread = `${Math.floor(distance / 2)}px`
  return css`
    box-shadow: 
      -${pos} -${pos} ${blur} ${spread} ${rgba(primary, 0.7)},
       ${pos}  ${pos} ${blur} ${spread} ${rgba(secondary, 0.7)};
  `
}
