import Button from '@material-ui/core/Button'
import { lighten } from 'polished'
import T from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { boxShadow, confirm, primary, secondary } from './palette'

const GRADIENT = {
  primary: { from: primary, to: lighten(0.2, confirm) },
  secondary: { from: lighten(0.2, confirm), to: lighten(0.2, secondary) }
}

export const StyledButton = styled(({ styleType, ...rest }) => <Button {...rest} />)`
  color: white;
  font-weight: 700;
  padding: 0 20px;
  ${({ styleType }) => css`
    background: linear-gradient(
      45deg, 
      ${GRADIENT[styleType].from} 30%, 
      ${GRADIENT[styleType].to} 90%
    );
  `};
  border: 0;
  border-radius: 4px;
  ${boxShadow(5)};
  height: 48px;
`

StyledButton.propTypes = {
  styleType: T.oneOf(['primary', 'secondary'])
}

StyledButton.defaultProps = {
  styleType: 'primary'
}

export const SmallStyledButton = styled(StyledButton)`
  && {
    height: 36px;
    font-size: 12px;
  }
`
