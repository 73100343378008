import { useEffect, useRef, useState } from 'react'
import { firestore } from './firebase'

export function usePrevious (value) {
  const ref = useRef()
  useEffect(() => { ref.current = value }, [value])
  return ref.current
}

export function useFocusRef () {
  const ref = useRef()
  useEffect(() => ref.current && ref.current.focus(), [])
  return ref
}

export function useDocument (path) {
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    const receiveDoc = doc => setData(doc.data())

    // fetch once
    firestore.doc(path).get().then(receiveDoc, setError)

    // Listen for future changes
    return firestore.doc(path).onSnapshot(receiveDoc, setError)
  }, [path])

  return { data, error }
}

export function useCollectionIds (collection, queryBuilder) {
  const [error, setError] = useState()
  const [ids, setIds] = useState([])

  useEffect(() => {
    queryBuilder(firestore.collection(collection))
      .get()
      .then(
        snapshot => {
          const ids = []
          snapshot.forEach(({ id }) => ids.push(id))
          setIds(ids)
        },
        setError
      )
  }, [collection, queryBuilder])

  return { ids, error }
}
