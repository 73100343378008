import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

firebase.initializeApp({
  apiKey: 'AIzaSyDlxwznfZrOvxR2dqLLJpu35ViqbqWrX54',
  authDomain: 'cyndy-memorial-dev.firebaseapp.com',
  databaseURL: 'https://cyndy-memorial-dev.firebaseio.com',
  projectId: 'cyndy-memorial-dev',
  storageBucket: 'cyndy-memorial-dev.appspot.com',
  messagingSenderId: '924022706592',
  appId: '1:924022706592:web:abbf65060e86f7025b14c5',
  measurementId: 'G-SSZDLTY0TH'
})

export const auth = firebase.auth()
export const firestore = firebase.firestore()

const provider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => auth.signInWithPopup(provider)

