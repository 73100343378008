import firebase from 'firebase/app'
import { useEffect, useState } from 'react'
import { BehaviorSubject, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { auth, firestore } from '../firebase'

async function createMemory ({ title, content }) {
  const user = auth.currentUser
  if (user == null) {
    throw new Error('User must be logged in to create memories')
  }

  const userDoc = await firestore.doc(`users/${user.uid}`).get()
  const { displayName } = userDoc.data()

  const ref = await firestore.collection('memories').add({
    title,
    content,
    authorId: user.uid,
    authorName: displayName,
    published: false,
    likes: 0,
    createdAt: firebase.firestore.Timestamp.now(),
    updatedAt: null,
    publishedAt: null
  })
  return ref.id
}

async function updateMemory (memoryId, { title, content }) {
  const ref = firestore.doc(getMemoryPath(memoryId))
  await ref.set({
    title,
    content,
    published: false,
    updatedAt: firebase.firestore.Timestamp.now()
  }, { merge: true })
}

const getMemoryPath = memoryId => `memories/${memoryId}`

const user = new BehaviorSubject(auth.currentUser)
auth.onAuthStateChanged(doc => { user.next(doc) })

const generateDocs = userDoc => {
  if (userDoc == null) return of(null)

  const memories = new BehaviorSubject([])
  const query = firestore
    .collection('memories')
    .where('authorId', '==', userDoc.uid)
    .orderBy('createdAt')

  const receiveSnapshot = snapshot => {
    const docs = []
    snapshot.forEach(doc => docs.push({ memoryId: doc.id, memory: doc.data() }))
    memories.next(docs)
  }

  const receiveError = error => memories.error(error)

  query.get().then(receiveSnapshot, receiveError)
  query.onSnapshot(receiveSnapshot)
  return memories
}
const myMemories = user.pipe(mergeMap(generateDocs))

function useMyMemories () {
  const [docs, setDocs] = useState([])
  const [error, setError] = useState(null)
  useEffect(() => {
    const subscription = myMemories.subscribe({ next: setDocs, error: setError })
    return () => subscription.unsubscribe()
  }, [])
  return { memories: docs, error }
}

export const Memory = {
  create: createMemory,
  update: updateMemory,
  path: getMemoryPath,
  useMine: useMyMemories
}
