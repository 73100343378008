import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import MemoryCard from '../../components/MemoryCard'
import { Memory } from '../../lib/documents'
import { useDocument } from '../../lib/hooks'
import { ContentArea, ContentBox, Divider, FlexForm, FlexSpacer } from '../../theme/layout'
import { SmallStyledButton } from '../../theme/mui'
import { Title } from '../../theme/type'

export function EditMemory ({ memoryId, setError }) {
  const { data: memory, error } = useDocument(Memory.path(memoryId))
  const [title, setTitle] = useState((memory && memory.title) || '')
  const [content, setContent] = useState((memory && memory.content) || '')

  useEffect(() => {
    if (memory == null) return
    setTitle(memory.title)
    setContent(memory.content)
  }, [memory])

  useEffect(() => { setError(error) }, [setError, error])

  const updateTitle = event => {
    setTitle(event.target.value)
    setError(null)
  }

  const updateContent = event => {
    setContent(event.target.value)
    setError(null)
  }

  const saveMemory = event => {
    if (event != null) event.preventDefault()
    Memory.update(memoryId, { title, content }).catch(setError)
  }

  const textModified = memory && (memory.title !== title || memory.content !== content)

  return memory && (
    <ContentArea>
      <ContentBox>
        <Title>Edit Memory</Title>
        <FlexForm onSubmit={saveMemory} noValidate>
          <FlexSpacer />
          <TextField
            label='Memory Title'
            value={title}
            onChange={updateTitle}
            variant='outlined'
          />
          <FlexSpacer />
          <TextField
            label='Memory Content'
            value={content}
            onChange={updateContent}
            variant='outlined'
            multiline
          />
          <FlexSpacer />
          <SmallStyledButton type='submit' disabled={!textModified}>
            Update Memory
          </SmallStyledButton>
        </FlexForm>
      </ContentBox>
      <Divider border />
      <Title>Saved Memory</Title>
      <FlexSpacer />
      <MemoryCard memoryId={memoryId} memory={memory} showUnpublished />
    </ContentArea>
  )
}
