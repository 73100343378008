import T from 'prop-types'
import React from 'react'
import { signInWithGoogle } from '../../lib/firebase'
import { StyledButton } from '../../theme/mui'
import { Body, SubTitle, Title } from '../../theme/type'
import Page from '../Page'
import { EmailDisclaimer, InputRow, ContentBox, ContentArea } from '../../theme/layout'

export function Landing ({ navigate, setError }) {
  const goToEmail = () => navigate('./email')
  const goToDisplayName = () => navigate('./display-name')
  const googleSignUp = () => signInWithGoogle().then(goToDisplayName).catch(setError)

  return (
    <ContentArea>
      <ContentBox>
        <Title>Cynthia Patterson Memorial Site Sign Up</Title>
        <SubTitle>
          Sign up to share memories and be notified when new memories have been shared.
        </SubTitle>
        <EmailDisclaimer />
        <InputRow>
          <StyledButton onClick={goToEmail}>Sign Up With Email</StyledButton>
          <StyledButton onClick={googleSignUp} styleType='secondary'>
            Sign Up With Google
          </StyledButton>
        </InputRow>
        <Body>
          Already have an account? {Page.SIGN_IN.linkWith('Sign in')}
        </Body>
      </ContentBox>
    </ContentArea>
  )
}

Landing.propTypes = {
  setError: T.func.isRequired
}
