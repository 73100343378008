import { lighten } from 'polished'
import T from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import { boxBackground, boxShadow, primary, secondary } from './palette'
import { Body, SubTitle } from './type'

export const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 0 50px 0;
`

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;
`

export const CONTENT_WIDTH = '800px'

export const ContentBox = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  background: ${boxBackground};
  padding: 10px 36px 20px 36px;
  box-sizing: border-box;
  border-radius: 4px;
  ${boxShadow()};
  width: ${CONTENT_WIDTH};
  
  ${SubTitle} {
    margin: 0 100px;
  }
  
  li {
    text-align: left;
  }
`

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify};
  margin: 36px 0 20px 0;
  align-items: center;
`

InputRow.propTypes = {
  justify: T.oneOf(['space-around', 'center'])
}

InputRow.defaultProps = {
  justify: 'space-around'
}

export const FlexSpacer = styled.span`
  flex-basis: 30px;
`

const edgeColor = lighten(0.33, primary)
export const Divider = styled.hr`
  border: ${({ border }) => border ? `1px solid ${secondary}` : 'none'};
  background: linear-gradient(to right, ${edgeColor}, ${primary}, ${edgeColor});
  height: 3px;
  border-radius: 3px;
  width: 100%;
  margin: 30px 0;
`

Divider.propTypes = {
  border: T.bool
}

Divider.defaultProps = {
  border: false
}

export const EmailDisclaimer = () => (
  <Body>
    We will never send you unsolicited email, nor share your email address publicly.
  </Body>
)

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const FlexForm = styled.form`
  display: flex;
  flex-direction: column;
`
