import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import { auth } from '../lib/firebase'
import { useFocusRef } from '../lib/hooks'
import { ContentBox, FlexSpacer, InputRow, PageDiv } from '../theme/layout'
import { SmallStyledButton } from '../theme/mui'
import { Error, Notice, Title } from '../theme/type'

function PasswordReset () {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [resetSent, setResetSent] = useState(false)
  const emailRef = useFocusRef()
  useEffect(() => {
    if (resetSent) {
      setTimeout(() => {
        setEmail('')
        setResetSent(false)
      }, 3000)
    }
  }, [resetSent])

  const onChange = event => {
    setEmail(event.target.value)
    setError('')
  }
  const onSubmit = event => {
    if (event != null) event.preventDefault()
    auth.sendPasswordResetEmail(email).then(() => setResetSent(true)).catch(setError)
  }

  return (
    <PageDiv>
      {error && (
        <>
          <Error>{error.message}</Error>
          <FlexSpacer />
        </>
      )}
      <ContentBox>
        <Title>Password Reset</Title>
        <InputRow justify='center' as='form' noValidate onSubmit={onSubmit}>
          <TextField
            inputRef={emailRef}
            label='Email Address'
            type='email'
            value={email}
            onChange={onChange}
            variant='outlined'
          />
          <FlexSpacer />
          <SmallStyledButton type='submit'>Send Password Reset Email</SmallStyledButton>
        </InputRow>
        <FlexSpacer />
        {resetSent && (
          <>
            <Notice>Password reset email sent to {email}</Notice>
            <FlexSpacer />
          </>
        )}
      </ContentBox>
    </PageDiv>
  )
}

export default PasswordReset
