import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { auth, signInWithGoogle } from '../lib/firebase'
import { useFocusRef } from '../lib/hooks'
import { ContentBox, FlexRow, FlexSpacer, InputRow, PageDiv } from '../theme/layout'
import { SmallStyledButton, StyledButton } from '../theme/mui'
import { Body, Error, SubTitle, Title } from '../theme/type'
import Page from './Page'

function SignIn ({ navigate }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const emailRef = useFocusRef()

  const goHome = () => navigate(Page.HOME.path)
  const googleSignIn = () => signInWithGoogle().then(goHome).catch(setError)
  const changeHandler = setter => event => {
    setter(event.target.value)
    setError('')
  }
  const passwordSignIn = event => {
    if (event != null) event.preventDefault()
    auth.signInWithEmailAndPassword(email, password).then(goHome).catch(setError)
  }

  return (
    <PageDiv>
      {error && (
        <>
          <Error>{error.message}</Error>
          <FlexSpacer />
        </>
      )}
      <ContentBox>
        <Title>Sign In - Cynthia Patterson Memorial Site</Title>
        <InputRow justify='center' as='form' noValidate onSubmit={passwordSignIn}>
          <TextField
            inputRef={emailRef}
            label='Email'
            type='email'
            variant='outlined'
            value={email}
            onChange={changeHandler(setEmail)}
          />
          <FlexSpacer />
          <TextField
            label='Password'
            type='password'
            variant='outlined'
            value={password}
            onChange={changeHandler(setPassword)}
          />
          <FlexSpacer />
          <SmallStyledButton type='submit'>Sign in</SmallStyledButton>
        </InputRow>
        <SubTitle>or</SubTitle>
        <FlexSpacer />
        <FlexRow>
          <StyledButton styleType='secondary' onClick={googleSignIn}>
            Sign in with Google
          </StyledButton>
        </FlexRow>
        <FlexSpacer />
        <Body>
          <FlexRow>
            Don't have an account?&nbsp;{Page.SIGN_UP.linkWith('Sign up')}
            <FlexSpacer />
            {Page.PASSWORD_RESET.linkWith('Forgot Password?')}
          </FlexRow>
        </Body>
      </ContentBox>
    </PageDiv>
  )
}

export default SignIn
