import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { StylesProvider } from '@material-ui/styles'
import { Router } from '@reach/router'
import React from 'react'
import styled from 'styled-components/macro'
import Navigation from './components/Navigation'
import { UserProvider } from './lib/documents/User'
import Page from './pages/Page'
import { background, confirm, error, primary, secondary, text } from './theme/palette'

const muiTheme = createMuiTheme({
  palette: {
    // MUI calculates the non main colors from the main color if not provided
    primary: { main: primary },
    secondary: { main: secondary },
    error: { main: error },
    success: { main: confirm },
    info: { main: primary },
    warning: { main: error }
  },
  typography: {
    fontFamily: [
      'Comfortaa',
      'apple-system',
      'BlinkMacSystemFont',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
    fontSize: 14
  }
})

const AppContainer = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background: ${background};
  color: ${text};
`

const App = () => (
  <AppContainer>
    <UserProvider>
      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <Navigation />
          <Router>
            {Page.enumValues.map(({ route }) => route)}
          </Router>
        </ThemeProvider>
      </StylesProvider>
    </UserProvider>
  </AppContainer>
)

export default App
