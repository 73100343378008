import { useNavigate } from '@reach/router'
import React from 'react'
import Page from '../pages/Page'
import { ContentBox, InputRow, PageDiv } from '../theme/layout'
import { StyledButton } from '../theme/mui'
import { SubTitle, Title } from '../theme/type'

export function SignInRequired ({ children }) {
  const navigate = useNavigate()
  const signIn = () => navigate(Page.SIGN_IN.path)
  const signUp = () => navigate(Page.SIGN_UP.path)

  return (
    <PageDiv>
      <ContentBox>
        <Title>Sign In Required</Title>
        <SubTitle>{children}</SubTitle>
        <InputRow>
          <StyledButton onClick={signIn}>Sign In</StyledButton>
          <StyledButton styleType='secondary' onClick={signUp}>Sign Up</StyledButton>
        </InputRow>
      </ContentBox>
    </PageDiv>
  )
}
