import React, { useEffect } from 'react'
import { MemoryMasonry } from '../../components/MemoryMasonry'
import { Memory } from '../../lib/documents'
import { ContentArea, ContentBox, Divider, InputRow } from '../../theme/layout'
import { StyledButton } from '../../theme/mui'
import { SubTitle, Title } from '../../theme/type'

export function Landing ({ navigate, setError }) {
  const { error, memories } = Memory.useMine()
  useEffect(() => { if (error != null) setError(error) }, [setError, error])

  const goToCreate = () => navigate('./new')

  return (
    <ContentArea>
      <ContentBox>
        <Title>Your Memories of Cynthia</Title>
        <SubTitle>
          You may edit your existing memories below, or create a new one.
        </SubTitle>
        <InputRow>
          <StyledButton onClick={goToCreate}>Add New Memory</StyledButton>
        </InputRow>
      </ContentBox>
      <Divider border />
      {memories && <MemoryMasonry memories={memories} />}
    </ContentArea>
  )
}
