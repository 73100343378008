import Button from '@material-ui/core/Button'
import React from 'react'
import styled from 'styled-components/macro'
import { User } from '../lib/documents'
import { auth } from '../lib/firebase'
import Page, { StyledLink } from '../pages/Page'

const NavContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100vw;
  
  ${StyledLink} {
    margin: 10px;
  }
`

function Navigation () {
  const user = User.useCurrent()
  const shouldRenderLink =
    ({ signedIn, signedOut }) => (user === null && signedOut) || (user !== null && signedIn)

  return (
    <NavContainer>
      {Page.enumValues.filter(shouldRenderLink).map(({ link }) => link)}
      {user && (
        <Button variant='contained' onClick={() => auth.signOut()}>Sign Out</Button>
      )}
    </NavContainer>
  )
}

export default Navigation
