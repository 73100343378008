import { Masonry } from 'masonic'
import React from 'react'
import styled from 'styled-components/macro'
import { Divider } from '../theme/layout'
import MemoryCard from './MemoryCard'

const Brick = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const CardDivider = styled(Divider)`
  position: absolute;
  bottom: -60px;
`

const BrickMemoryCard = styled(MemoryCard)`
  width: 100%;
`

const MemoryBrick = ({ width, data: { memory, memoryId } }) => (
  <Brick width={width}>
    <BrickMemoryCard memory={memory} memoryId={memoryId} showUnpublished />
    <CardDivider />
  </Brick>
)

export const MemoryMasonry = ({ memories }) => (
  <Masonry
    items={memories}
    render={MemoryBrick}
    columnWidth={300}
    columnGutter={60}
  />
)
