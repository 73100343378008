import React, { useState } from 'react'
import { User } from '../../lib/documents'
import { auth } from '../../lib/firebase'
import { FlexSpacer, PageDiv } from '../../theme/layout'
import TransitionRouter from '../../components/TransitionRouter'
import { Error } from '../../theme/type'
import { DisplayName, Email, Password } from './InputSteps'
import { Landing } from './Landing'

const PATHS = ['', 'email', 'password', 'display-name']

function SignUp ({ navigate }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [error, setError] = useState(null)

  const completeEmail = () => {
    setError('')
    navigate('./password')
  }
  const completePassword = () => {
    setError('')
    const goToDisplayName = () => navigate('./display-name')
    auth.createUserWithEmailAndPassword(email, password).then(goToDisplayName).catch(setError)
  }
  const completeDisplayName = () => {
    setError('')
    const goToProfile = () => navigate('/profile')
    User.updateDisplayName(displayName).then(goToProfile).catch(setError)
  }

  return (
    <PageDiv>
      {error && (
        <>
          <Error>{error.message}</Error>
          <FlexSpacer />
        </>
      )}
      <TransitionRouter paths={PATHS} root='sign-up'>
        <Landing path='/' {...{ setError }} />
        <Email
          path='email'
          {...{ setError, email, setEmail }}
          onComplete={completeEmail}
        />
        <Password
          path='password'
          {...{ setError, password, setPassword }}
          onComplete={completePassword}
        />
        <DisplayName
          path='display-name'
          {...{ setError, displayName, setDisplayName }}
          onComplete={completeDisplayName}
        />
      </TransitionRouter>
    </PageDiv>
  )
}

export default SignUp
