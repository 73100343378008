import { Router, useLocation } from '@reach/router'
import T from 'prop-types'
import React, { useContext, useMemo } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components/macro'
import { usePrevious } from '../lib/hooks'
import { ContentArea } from '../theme/layout'

const TRANSITION_MS = 500
const TRANSITION_OFFSET = 300

const ContextualStyledRouter = props => {
  // This is a workaround to get the StyledRouter to have the current value of `forward`, rather
  // than what got cached by `CSSTransition`
  const forward = useContext(ForwardContext)
  return <StyledRouter {...{ forward, ...props }} />
}

const StyledRouter = styled(({ forward, ...rest }) => <Router {...rest} />)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  
  & > ${ContentArea} {
    transition: top ${TRANSITION_MS}ms, opacity ${TRANSITION_MS}ms;
  }
  
  &.slide-enter > ${ContentArea} {
    position: absolute;
    opacity: 0;
    top: ${({ forward }) => forward ? TRANSITION_OFFSET : -TRANSITION_OFFSET}px;
  }
  &.slide-enter-active > ${ContentArea} {
    opacity: 1;
    top: 0;
  }
  &.slide-exit > ${ContentArea} {
    position: absolute;
    opacity: 1;
    top: 0;
  }
  &.slide-exit-active > ${ContentArea} {
    opacity: 0;
    top: ${({ forward }) => forward ? -TRANSITION_OFFSET : TRANSITION_OFFSET}px;
  }
`

const ForwardContext = React.createContext(null)

export default function TransitionRouter ({ children, paths, root }) {
  const location = useLocation()
  const previousLocation = usePrevious(location)

  const forward = useMemo(() => {
    if (previousLocation == null) return true

    const { pathname: path } = location
    const { pathname: previousPath } = previousLocation
    if (!path.startsWith(`/${root}`) || !previousPath.startsWith(`/${root}`)) return true

    const re = new RegExp(`/${root}($|/([^/]*)).*`)
    const index = paths.indexOf(path.replace(re, '$2'))
    const previousIndex = paths.indexOf(previousPath.replace(re, '$2'))
    if (index < 0 || previousIndex < 0) {
      console.warn('Unknown path', path, previousPath)
      return true
    }

    return index > previousIndex
  }, [paths, root, previousLocation, location])

  return (
    <ForwardContext.Provider value={forward}>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames='slide' timeout={TRANSITION_MS}>
          <ContextualStyledRouter location={location}>
            {children}
          </ContextualStyledRouter>
        </CSSTransition>
      </TransitionGroup>
    </ForwardContext.Provider>
  )
}

TransitionRouter.propTypes = {
  paths: T.arrayOf(T.string).isRequired,
  root: T.string.isRequired
}
