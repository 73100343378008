import styled from 'styled-components/macro'
import { confirm, error, secondary } from './palette'

export const Title = styled.span`
  font-size: 28px;
  font-weight: 700;
  padding: 10px 0;
  color: ${secondary};
`

export const SubTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  padding: 6px 0;
`

export const Body = styled.span`
  font-size: 16px;
  font-weight: 400;
  padding: 4px 0;
`

export const Label = styled.span`
  font-weight: 700;
  color: ${confirm};
`

export const Notice = styled.span`
  font-weight: 700;
  color: ${confirm};
`

export const Error = styled(SubTitle)`
  color: ${error};
`
