import isEmpty from 'lodash/isEmpty'
import T from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { auth, firestore } from '../firebase'
import { usePrevious } from '../hooks'

function listenOnUserDoc (uid, onNext, onError) {
  // fetch once
  const receiveDoc = userDoc => onNext({ uid, ...(userDoc && userDoc.data()) })
  firestore.doc(`users/${uid}`).get().then(receiveDoc, onError)

  // Listen for future changes
  return firestore.doc(`users/${uid}`).onSnapshot(receiveDoc, onError)
}

const UserContext = React.createContext(null)

// This is kept as a context provider instead of using observables so that it can control
// if the rest of the react tree is mounted when it's mid-auth process. We do isolate its usage
// a bit by making components access through a hook, so we could change it later if necessary.
export function UserProvider ({ children }) {
  const [user, setUser] = useState(false)
  const [userDoc, setUserDoc] = useState(null)
  const userDocCancel = useRef(null)
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setUserDoc(null)
      if (userDocCancel.current != null) userDocCancel.current()
      user == null ? setUser({ uid: '' }) : setUser(user)
    })
  }, [])

  const previousUid = usePrevious(user.uid)
  useEffect(() => {
    if (previousUid === user.uid || isEmpty(user.uid)) return

    const onNext = userDoc => setUserDoc({ uid: user.uid, ...userDoc })
    const onError = error => console.log('Error on user doc', error)
    userDocCancel.current = listenOnUserDoc(user.uid, onNext, onError)
  }, [user.uid, previousUid])

  // even if we don't have a user, we'll get one "auth state changed" on landing.
  if (user === false || (!isEmpty(user.uid) && userDoc == null)) return null

  return <UserContext.Provider value={userDoc}>{children}</UserContext.Provider>
}

UserProvider.propTypes = {
  children: T.node
}

async function updateDisplayName (displayName) {
  const user = auth.currentUser
  if (user == null) {
    throw new Error('User must not be null to update the user doc')
  }

  const ref = firestore.doc(`users/${user.uid}`)
  await ref.set({ displayName, email: user.email }, { merge: true })
}

const useCurrent = () => useContext(UserContext)

export const User = { updateDisplayName, useCurrent }
