import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { createGlobalStyle } from 'styled-components/macro'
import COMFORTAA from './Comfortaa.ttf'
import { text } from './theme/palette'

const GlobalStyle = createGlobalStyle`
  @font-face {
    src: url(${COMFORTAA});
    font-family: 'Comfortaa'
  }

  body {
    margin: 0;
    font-family: 'Comfortaa', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${text};
  }
  
  a {
    color: ${text};
    &:hover { color: ${text}; }
    &:visited { color: ${text}; }
  }
`

ReactDOM.render(
  <>
    <GlobalStyle />
    <App />
  </>,
  document.getElementById('root')
)
